import { FlowsBar, FlowsBars } from '@/store/investigations/flows'
import { Flow, FlowRange, IdType, Link, Node, Ranges, TransAmount, VizTransaction } from '@/store/investigations/viz'
import { ascending, format } from 'd3'
import { TransactionFlow, FlowInfo, ComparisonConstraints, AggregatedBitcoinTransaction, BasicLinkData, EntityType } from './api'
import { addBigNum, greatestIndex } from './bignum'
import { cutMiddle, timeToMilliseconds } from './general'
import { NetworkTypes, classifyInput, isBitcoinClassification, isEthereumClassification } from './validate'
import { LRUCache } from '@splunkdlt/cache'
import { ContractInfo, FormattedLogEvent, isTokenProps } from '@/types/eth'

export interface TransactionFlowMap {
  [key: string]: SingleTransactionFlow
}

export interface SingleTransactionFlow {
  transaction: AggregatedBitcoinTransaction
  amount: number
  shortestPath: number
}

export interface SubnetworkFlowTransaction extends AggregatedBitcoinTransaction {
  receivedFlow: FlowInfo
  sentFlow: FlowInfo
}

export interface SubnetworkFlowMap {
  [key: string]: SubnetworkFlowTransaction
}

export interface NodeType {
  id: string
  type: IdType
  network: string
}

export function transAmountBigNumSum(arr: TransAmount[]): string {
  return addBigNum(...arr.map((t) => t.amount))
}

export function transAmountGreatestIndex(arr: TransAmount[]): number {
  return greatestIndex(arr.map((t) => t.amount))
}

export function rgbToHex(rgb: string) {
  return parseInt(
    rgb.replace(/rgb\((.+?)\)/gi, (_, rgb) => {
      return rgb
        .split(',')
        .map((str: string) => parseInt(str, 10).toString(16).padStart(2, '0'))
        .join('')
    }),
    16
  )
}

export function formatTarget(target: Array<string>) {
  if (target.length > 1) {
    //source-dest
    return `${cutMiddle(target[0])}→${cutMiddle(target[1])}`
  } else return `${cutMiddle(target[0])}`
}

export function dateRange(
  arr: TransAmount[],
  formatDate: (epoch: number, ms: boolean, shortness?: number) => string
): string {
  const firstDate = timeToMilliseconds(arr[0].timestamp)
  const lastDate = timeToMilliseconds(arr[arr.length - 1].timestamp)
  if (firstDate === lastDate) {
    return formatDate(firstDate, true, 1)
  }
  return `${formatDate(firstDate, true, 2)} - ${formatDate(lastDate, true, 2)}`
}

export function summaryLinkTimeLabel(
  formatDate: (epoch: number, ms: boolean, shortness?: number) => string,
  first: number,
  last: number
): string {
  const firstDate = timeToMilliseconds(first)
  if (first === last) {
    return formatDate(firstDate, true, 1)
  }
  const lastDate = timeToMilliseconds(last)
  return `${formatDate(firstDate, true, 2)} - ${formatDate(lastDate, true, 2)}`
}

export function formatPercent(num: number) {
  return format('.1~%')(num)
}

export function networkSymbol(network?: string): string {
  switch (network) {
    case 'bitcoin':
      return 'BTC'
    case 'ethereum':
      return 'ETH'
    default:
      return '?'
  }
}

export function symbolNetwork(symbol?: string): NetworkTypes {
  switch (symbol) {
    case '':
    case null:
      return ''
    case 'BTC':
      return 'bitcoin'
    case 'ETH':
    default:
      return 'ethereum'
  }
}

export function graphDataFromTargets(targets: string[]): { nodes: Node[]; links: Link[]; ranges: Ranges } {
  const nodes = targets.map(t => ({
    id: t,
    size: 0,
    appearance: 0
  }))
  const links: Link[] = []
  const ranges: Ranges = {
    appearance: {
      min: Infinity,
      max: 0
    },
    flows: {}
  }
  return { nodes, links, ranges }
}

export function graphDataFromLedger(ledger: VizTransaction[]): { nodes: Node[]; links: Link[]; ranges: Ranges } {
  // group transactions into nodes and links
  let minAppeared, maxAppeared
  minAppeared = Infinity
  maxAppeared = 0
  let checkMinMaxAppeared = false
  let checkMinMaxFlow = false
  let checkMinMaxHops = false
  const flowMins: { [target: string]: number } = {}
  const flowMaxes: { [target: string]: number } = {}
  const hopMins: { [target: string]: number } = {}
  const hopMaxes: { [target: string]: number } = {}
  const edgeAmounts: { [edge: string]: TransAmount } = {}
  const nodeAppeared: { [node: string]: number } = {}
  const nodeFlows: { [node: string]: { [key: string]: Flow } } = {}
  const permanentNodes = new Set<string>()
  for (const txn of ledger) {
    const { id, sender, receiver, amount, symbol, flows, timestamp, permanent } = txn
    for (const node of [sender, receiver]) {
      // track first appearance
      if (!nodeAppeared[node] || timestamp < nodeAppeared[node]) {
        nodeAppeared[node] = timestamp
        checkMinMaxAppeared = true
      }
      if (checkMinMaxAppeared) {
        const millis = timestamp
        if (millis > maxAppeared) maxAppeared = millis
        if (millis < minAppeared) minAppeared = millis
        checkMinMaxAppeared = false
      }

      // track flows
      if (flows) {
        if (!nodeFlows[node]) nodeFlows[node] = {}
        for (const flow of flows) {
          const { id, isOutput, index } = flow.targetTransaction
          const key = `${id}|${isOutput}|${index}|${flow.sending}`
          if (!nodeFlows[node][key]) {
            nodeFlows[node][key] = flow
            checkMinMaxFlow = true
            checkMinMaxHops = true
          } else {
            if (flow.value > nodeFlows[node][key].value) {
              nodeFlows[node][key] = flow
              checkMinMaxFlow = true
              checkMinMaxHops = true
            } else {
              checkMinMaxHops = true
            }
          }
          const { value, minHops } = flow
          if (checkMinMaxFlow) {
            if (!flowMins[key]) {
              flowMins[key] = value
              flowMaxes[key] = value
            } else {
              if (flowMins[key] > value) flowMins[key] = value
              if (flowMaxes[key] < value) flowMaxes[key] = value
            }
            checkMinMaxFlow = false
          }
          if (checkMinMaxHops) {
            if (!hopMins[key]) {
              hopMins[key] = minHops
              hopMaxes[key] = minHops
            } else {
              if (hopMins[key] > minHops) hopMins[key] = minHops
              if (hopMaxes[key] < minHops) hopMaxes[key] = minHops
            }
            checkMinMaxHops = false
          }
        }
      }

      if (permanent) permanentNodes.add(node)
    }

    // combine into edges
    const edge = `${sender}_${receiver}`
    edgeAmounts[edge] = { id, timestamp, amount, symbol, flows }
  }
  const links = Object.keys(edgeAmounts).map((edge) => {
    const nodes = edge.split('_')
    const permanent = permanentNodes.has(nodes[0]) && permanentNodes.has(nodes[1])
    return { source: nodes[0], target: nodes[1], amount: edgeAmounts[edge], permanent }
  })

  const nodes: Node[] = Object.entries(nodeAppeared).map(([n, appearance]) => {
    const flows = nodeFlows[n] ? Object.values(nodeFlows[n]) : undefined
    const permanent = permanentNodes.has(n)
    return {
      id: n,
      size: 0,
      appearance,
      flows,
      permanent
    }
  })

  const flowRanges: { [target: string]: FlowRange } = {}
  for (const key of Object.keys(flowMins)) {
    flowRanges[key] = {
      flows: {
        min: flowMins[key],
        max: flowMaxes[key]
      },
      hops: {
        min: hopMins[key],
        max: hopMaxes[key]
      }
    }
  }
  const ranges: Ranges = {
    appearance: {
      min: minAppeared,
      max: maxAppeared
    },
    flows: flowRanges
  }

  return { nodes, links, ranges }
}

export function convertHopsToReadable(hops: number, input: boolean, sending: boolean): number {
  if ((input && sending) || (!input && !sending)) {
    hops++
  }
  return Math.trunc(hops / 2) + 1
}

export function convertHopsToDB(
  hops: { [constraint: string]: number },
  isOutput: boolean,
  sending: boolean
): ComparisonConstraints {
  if ('eq' in hops) {
    const converted = (hops['eq'] - 1) * 2
    return {
      gte: (!isOutput && sending) || (isOutput && !sending) ? converted - 1 : converted,
      lte: (!isOutput && sending) || (isOutput && !sending) ? converted : converted + 1
    }
  }
  for (const [constraint, value] of Object.entries(hops)) {
    const converted = (value - 1) * 2
    if (constraint === 'lte' || constraint === 'gt') {
      hops[constraint] = (!isOutput && sending) || (isOutput && !sending) ? converted : converted + 1
    } else {
      // gte or lt
      hops[constraint] = (!isOutput && sending) || (isOutput && !sending) ? converted - 1 : converted
    }
  }
  return hops
}

export function barDataFromFlows(
  isOutput: boolean,
  sending: boolean,
  flows: TransactionFlow[]
): { bars: FlowsBars; map: TransactionFlowMap; hops: string[]; addresses: string[]; attributions: string[] } {
  const bars: FlowsBars = [{}]

  const hopsBarsMap: { [hops: string]: FlowsBar } = {}

  const map: TransactionFlowMap = {}
  const addresses = new Set<string>()
  const attributions = new Set<string>()
  flows.forEach((f) => {
    f.shortestPath = convertHopsToReadable(f.shortestPath, !isOutput, sending)
    const { amount, shortestPath } = f

    let transaction: AggregatedBitcoinTransaction
    if (sending) transaction = f.destination
    else transaction = f.source
    const { id, address, cluster, clusterAttribution, minIndex: index, isOutput: transactionIsOutput } = transaction
    if (clusterAttribution) attributions.add(clusterAttribution)
    if (cluster) addresses.add(cluster)
    if (address) addresses.add(address)

    const key = `${id}|${transactionIsOutput}|${index}`
    map[key] = { transaction, amount, shortestPath }
    if (!hopsBarsMap[shortestPath]) {
      hopsBarsMap[shortestPath] = { [key]: amount }
    } else {
      hopsBarsMap[shortestPath][key] = amount
    }
  })

  const ascendingHops = Object.keys(hopsBarsMap)
    .map((h) => parseInt(h))
    .sort(ascending)
    .map((h) => h.toString())
  for (const hop of ascendingHops) {
    bars.push(hopsBarsMap[hop])
  }

  return { bars, map, hops: ascendingHops, addresses: Array.from(addresses), attributions: Array.from(attributions) }
}

export function barDataFromSubnetwork(
  source: AggregatedBitcoinTransaction,
  destination: AggregatedBitcoinTransaction,
  subnetwork: TransactionFlow[]
): { bars: FlowsBars; map: SubnetworkFlowMap; hops: string[]; addresses: string[]; attributions: string[] } {
  const { id: sourceTxn, isOutput: sourceIsOutput, minIndex: sourceIndex } = source
  const sourceKey = `${sourceTxn}|${sourceIsOutput}|${sourceIndex ?? 0}`

  const bars: FlowsBars = [{}]

  const hopsBarsMap: { [hops: string]: FlowsBar } = {}

  const sentFlows: { [key: string]: FlowInfo } = {}
  const receivedFlows: { [key: string]: FlowInfo } = {}
  const map: SubnetworkFlowMap = {}
  const addressSet = new Set<string>()
  const attributionSet = new Set<string>()
  subnetwork.forEach((t) => {
    const {
      id: sourceId,
      isOutput: sourceIsOutput,
      minIndex: sIndex,
      clusterAttribution: sourceAttribution,
      cluster: sourceCluster,
      address: sourceAddress
    } = t.source
    const {
      id: destId,
      isOutput: destIsOutput,
      minIndex: dIndex,
      clusterAttribution: destAttribution,
      cluster: destCluster,
      address: destAddress
    } = t.destination
    if (sourceAttribution) attributionSet.add(sourceAttribution)
    if (sourceCluster) addressSet.add(sourceCluster)
    if (sourceAddress) addressSet.add(sourceAddress)
    if (destAttribution) attributionSet.add(destAttribution)
    if (destCluster) addressSet.add(destCluster)
    if (destAddress) addressSet.add(destAddress)
    const middleSourceKey = `${sourceId}|${sourceIsOutput}|${sIndex}`
    const middleDestKey = `${destId}|${destIsOutput}|${dIndex}`
    let finishedKey = ''
    let finishedTxn: AggregatedBitcoinTransaction
    if (sourceKey === middleSourceKey) {
      receivedFlows[middleDestKey] = t
      if (middleDestKey in sentFlows) {
        finishedKey = middleDestKey
        finishedTxn = t.destination
      }
    } else {
      sentFlows[middleSourceKey] = t
      if (middleSourceKey in receivedFlows) {
        finishedKey = middleSourceKey
        finishedTxn = t.source
      }
    }
    if (finishedKey) {
      const receivedFlow = receivedFlows[finishedKey]
      receivedFlow.shortestPath = convertHopsToReadable(receivedFlow.shortestPath, !sourceIsOutput, true)
      const sentFlow = sentFlows[finishedKey]
      sentFlow.shortestPath = convertHopsToReadable(sentFlow.shortestPath, !destination.isOutput, false)
      map[finishedKey] = {
        ...finishedTxn!,
        receivedFlow,
        sentFlow
      }
      const { shortestPath, amount } = receivedFlow
      if (!hopsBarsMap[shortestPath]) {
        hopsBarsMap[shortestPath] = { [finishedKey]: amount }
      } else {
        hopsBarsMap[shortestPath][finishedKey] = amount
      }
    }
  })

  const ascendingHops = Object.keys(hopsBarsMap)
    .map((h) => parseInt(h))
    .sort(ascending)
    .map((h) => h.toString())
  for (const hop of ascendingHops) {
    bars.push(hopsBarsMap[hop])
  }

  const addresses = Array.from(addressSet)
  const attributions = Array.from(attributionSet)

  return { bars, map, hops: ascendingHops, addresses, attributions }
}

export function classifyNodeId(nodeId: string): NodeType {
  if (nodeId.startsWith('-')) {
    const [network, id] = nodeId.slice(1).split('|') // if old, id will be undefined and network will be id
    return {
      id: id ?? network,
      type: 'transaction',
      network: id ? network : classifyInput(network).networkType
    }
  }
  let [typeCode, network, id] = nodeId.split('|')
  if (network != null) {
    if (id == null) { // network is actually id
      id = network
      network = classifyInput(id).networkType
    }
    switch (typeCode) {
      case 'ad':
        return {
          id: id,
          type: 'address',
          network
        }
      case 'cl':
        return {
          id: id,
          type: 'cluster',
          network
        }
      case 'at':
      default:
        return {
          id: id,
          type: 'attribution',
          network
        }
    }
  }
  // not explicitly typed, typeCode is id
  id = typeCode
  const classified = classifyInput(id)
  network = classified.networkType
  if (isBitcoinClassification(classified)) {
    if (classified.isBitcoinTxid) {
      return {
        id,
        type: 'transaction',
        network
      }
    } else {
      return {
        id,
        type: 'address',
        network
      }
    }
  } else if (isEthereumClassification(classified)) {
    if (classified.isEthAddress) {
      return {
        id,
        type: 'address',
        network
      }
    } else if (classified.isEthBlockOrHash) {
      return {
        id,
        type: 'transaction',
        network
      }
    }
  }
  // otherwise assume it's an attribution
  return {
    id,
    type: 'attribution',
    network: ''
  }
}

export function nodeTypeToIdString(nodeType: NodeType): string {
  const { id, type, network } = nodeType
  if (type === 'transaction') {
    return `-${network}|${id}`
  }
  return `${type.slice(0, 2)}|${network}|${id}`
}

export function txnContractName(id: string, receiptsCache: LRUCache<string, FormattedLogEvent>): string | undefined {
  const receipt: FormattedLogEvent | null = receiptsCache.get(id)
  if (receipt != null) {
    const { addressInfo } = receipt
    if (addressInfo != null && addressInfo.isContract) {
      const { contractName, properties } = addressInfo as ContractInfo
      if (isTokenProps(properties)) {
        return properties.name
      }
      return contractName
    }
  }
  return undefined
}

export function sameNetworkNodePairLinks(nodes: Array<NodeType>): { [network: string]: BasicLinkData[] } {
  const networkLinksData: { [network: string]: BasicLinkData[] } = {}
  for (var i = 0; i < nodes.length - 1; i++) {
    for (var j = i; j < nodes.length - 1; j++) {
      const node1 = nodes[i]
      const node2 = nodes[j+1]
      if (node1.network === node2.network) {
        if (!(node1.network in networkLinksData)) {
          networkLinksData[node1.network] = []
        }
        networkLinksData[node1.network].push(...[
          {
            sender: node1.id,
            senderType: node1.type as EntityType,
            receiver: node2.id,
            receiverType: node2.type as EntityType,
          },
          {
            sender: node2.id,
            senderType: node2.type as EntityType,
            receiver: node1.id,
            receiverType: node1.type as EntityType,
          }
        ])
      }
    }
  }
  return networkLinksData
}
