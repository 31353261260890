
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { BrowserCache } from './utils/cache'
import { machineId } from './utils/tracking'
import Dialog, { DialogConfig } from '@/components/Dialog.vue'
import SignInForm from '@/components/accounts/SignInForm.vue'
import SearchExplorer from '@/subcomponents/SearchExplorer.vue'
import TraceForm from '@/components/investigations/TraceForm.vue'
import Snackbar from '@/components/Snackbar.vue'
import Loader from '@/subcomponents/Loader.vue'

@Component({
  components: {
    Dialog,
    SearchExplorer,
    TraceForm,
    Snackbar
  },
  computed: mapState(['section'])
})
export default class App extends Vue {
  public section!: string
  public signInDialog: DialogConfig<typeof SignInForm> = {
    button: {
      icon: 'mdi-account-circle'
    },
    dialog: {
      title: 'Sign In',
      action: 'Sign In'
    },
    link: {
      label: 'Forgot Password',
      path: '/forgotpassword'
    },
    content: SignInForm
  }
  public loadingDialog: DialogConfig<typeof Loader> = {
    button: {
      icon: '',
      hide: true
    },
    dialog: {
      title: '',
      action: ''
    },
    content: Loader
  }
  public explorer: boolean = false
  public investigations: boolean = false
  private cache: BrowserCache = new BrowserCache(false)
  private unauthenticatedRoutes: string[] = ['/register', '/securityalert', '/forgotpassword', '/resetpassword']

  get signedIn(): boolean {
    return this.$store.state.signedIn
  }

  get showRoute(): boolean {
    return this.$store.state.signedIn || this.unauthenticatedRoutes.includes(this.$route.path)
  }

  get isAdmin() {
    return this.$store.state.username === 'admin' || this.$store.state.isAdmin
  }

  get localTime() {
    return this.$store.state.useLocalTime
  }

  set localTime(value: boolean) {
    this.$store.dispatch('setLocalTime', value)
    this.cache.add('localTime', value)
  }

  get darkMode() {
    return !!this.cache.get('darkMode')
  }

  set darkMode(value: boolean) {
    this.cache.add('darkMode', value)
    this.$vuetify.theme.dark = value
  }

  get externalAttributions() {
    return this.$store.state.useExternalApi
  }

  set externalAttributions(use: boolean) {
    this.$store.dispatch('setUseExternalApi', { use })
  }

  get autosaveOff() {
    return this.$store.state.autosaveOff
  }

  set autosaveOff(value: boolean) {
    this.$store.dispatch('setAutosaveOff', { value })
    this.cache.add('autosaveOff', value)
  }

  async created() {
    const cachedVersion = this.cache.get('version')
    const cachedTimePref: boolean = !!this.cache.get('localTime')
    const cachedAutosaveOff: boolean = !!this.cache.get('autosaveOff')
    this.$vuetify.theme.dark = !!this.cache.get('darkMode')
    this.localTime = cachedTimePref
    this.autosaveOff = cachedAutosaveOff

    let lastVersion = 0
    if (cachedVersion !== null) {
      lastVersion = parseInt(cachedVersion as string, 10)
    }
    if (lastVersion < this.$store.state.version) {
      this.cache.clear()
    }
    this.cache.add('version', this.$store.state.version as number)
    await machineId.init()
    this.checkSignedIn()
  }

  @Watch('$store.state.signedIn')
  private signInUpdated() {
    if (this.signedIn) {
      this.$store.dispatch('subscribeEvents')
    }
  }

  @Watch('$store.state.useLocalTime')
  private updateFormatDate() {
    this.$forceUpdate()
  }

  private checkSignedIn() {
    this.$store.dispatch('checkAuth')
  }

  public signOut() {
    this.$store.dispatch('signOut')
  }
}
