
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({})
export default class ColorPicker extends Vue {
  public showPicker: boolean = false
  public internalColor: any = null
  private init: boolean = false
  @Prop() color!: string
  @Prop() onUpdate!: (color: string) => void
  @Prop() disabled!: boolean

  public toggleShowPicker() {
    this.showPicker = !this.showPicker
  }
  public colorUpdated(color: any) {
    if (this.onUpdate != null && this.init) {
      this.onUpdate(color)
    }
    this.init = true
  }
  created() {
    this.internalColor = this.color
  }
}
